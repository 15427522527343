<template>
  <div>
    <el-card>
      <div class="info-flex-box">
        <el-form inline>
          <el-form-item label="资讯名称:">
            <el-input v-model="form.title" size="small" />
          </el-form-item>
          <el-form-item label="相关老兵:">
            <el-input v-model="form.veteranName" size="small" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getDataList">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="reset">重 置</el-button>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="addEditInfo">新增资讯</el-button>
        </div>
      </div>
      <el-table v-loading="dataListLoading" :data="newsList" style="width: 100%;padding: 8px 0 ! important;" :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}">
        <el-table-column label="资讯名称" prop="title" />
        <el-table-column label="创建时间">
          <template slot-scope="{row}">
            <span>{{ row.createTime | F_formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="资讯ID" prop="id" />
        <el-table-column label="相关老兵" prop="veteranName" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" size="small" @click="openDetail(row)">查看</el-button>
            <el-button type="text" size="small" @click="addEditInfo(row)">编辑</el-button>
            <el-button type="text" size="small" @click="deleteInfo(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size" :total="total" layout="total, sizes, prev, pager, next, jumper" style="padding:20px;" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </el-card>
    <!-- 查看 -->
    <el-dialog :visible.sync="visible" title="查看" width="65%" class="im-dialog">
      <div class="info-wrapper">
        <div class="info-title">{{ newsDetail.title }}</div>
        <div class="info-date">{{ newsDetail.createTime | F_formatDate }}</div>
        <el-button size="small" @click="openViewImage(1)">查看封面</el-button>
        <el-button size="small" @click="openViewImage(0)">查看分享海报</el-button>
        <div class="info-content" v-html="newsDetail.content" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible=false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 查看封面/海报 -->
    <el-dialog :visible.sync="imageVisible" :title="viewImageTitle" append-to-body width="50%">
      <div style="display:flex;justify-content:center">
        <img v-if="viewImageUrl" :src="viewImageUrl" style="width:70%">
        <div v-else>暂无信息</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="imageVisible=false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 添加/编辑咨讯 -->
    <add-edit-info ref="addEditInfo" @addEditSuccess="getDataList" />
    <!-- 老兵详情 -->
    <veteran-detail ref="veteranDetail" />
  </div>
</template>

<script>
import { getNewsList, deleteNews, veteranInfoList, getNewsDetail } from '@/utils/api.js'
import AddEditInfo from './components/AddEditInfo'
import VeteranDetail from '../veteran/components/VeteranDetail'
export default {
  components: { AddEditInfo, VeteranDetail },
  data() {
    return {
      form: {},
      newsList: [],
      dataListLoading: false,
      current: 1,
      size: 10,
      total: null,
      visible: false,
      newsDetail: {},
      imageVisible: false,
      viewImageTitle: '查看封面',
      viewImageUrl: '',
      peopleList: [],
      itemPeopleId: null
    }
  },
  mounted() {
    this.getDataList()
    this.getPeopleList()
  },
  methods: {
    async getPeopleList() {
      const res = await veteranInfoList({}, 1, 9999)
      this.peopleList = res.data.records
      console.log(this.peopleList)
    },
    async getDataList() {
      this.dataListLoading = true
      const res = await getNewsList(this.form, this.current, this.size)
      this.dataListLoading = false
      if (res.code === 200) {
        this.newsList = res.data.records
        this.current = res.data.current
        this.total = res.data.total
      } else {
        this.$message.error(res.message)
      }
    },
    reset() {
      this.form = {}
      this.getDataList()
    },
    addEditInfo(newsDetail) {
      if (newsDetail.id) {
        this.$refs.addEditInfo.open(newsDetail)
      } else {
        this.$refs.addEditInfo.open()
      }
    },
    // 查看
    async openDetail(val) {
      this.visible = true
      const res = await getNewsDetail(val.id)
      if (res.success) {
        this.newsDetail = res.data
      }
      Promise.resolve().then(() => {
        // 在数据渲染完成后执行
        const btnArr = document.querySelectorAll('.info-content #myButton')
        if (btnArr.length) {
          btnArr.forEach((value) => {
            const that = this
            const peopleName = value.innerHTML
            for (const item of that.peopleList) {
              if (item.name == peopleName) {
                that.itemPeopleId = item.id
              }
            }
            value.onclick = function() {
              that.getDetail(that.itemPeopleId)
              return false
            }
          })
        }
      })
    },
    // 打开老兵详情
    getDetail(id) {
      this.$refs.veteranDetail.open(id)
    },
    deleteInfo(id) {
      this.$confirm('是否确认删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteNews(id).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getDataList()
            } else {
              this.$message.error(res.message)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 打开查看封面/海报
    openViewImage(type) {
      if (type) {
        this.viewImageTitle = '查看封面'
        this.viewImageUrl = this.newsDetail.picture
        this.imageVisible = true
      } else {
        this.viewImageTitle = '查看分享海报'
        this.viewImageUrl = this.newsDetail.poster
        this.imageVisible = true
      }
    },

    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.info-flex-box {
  display: flex;
  justify-content: space-between;
}
.img-box {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  img {
    width: 100%;
  }
  .img-title {
    font-size: 16px;
    margin-top: 10px;
  }
}
.info-wrapper {
  padding: 0 5%;
  .info-title {
    font-size: 24px;
    color: #1e1e1e;
  }
  .info-date {
    font-size: 14px;
    color: #999999;
    margin: 12px 0;
  }
  .info-content {
    margin-top: 21px;
    overflow: auto;
  }
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
</style>
