<template>
  <div>
    <el-dialog :visible.sync="addVisible" title="选择老兵" append-to-body>
      <div>
        <el-form inline size="small">
          <el-form-item label="老兵名称:">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getVeteranList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          v-loading="dataListLoading"
          :data="veteranList"
          style="width: 100%;padding: 8px 0 ! important;"
          :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
        >
          <el-table-column width="55">
            <template slot-scope="{row}">
              <el-radio v-model="selectedVeteran" :label="row"><span>&nbsp;</span></el-radio>
            </template>
          </el-table-column>
          <el-table-column label="老兵姓名" prop="name" />
          <el-table-column label="性别" prop="sex" />
          <el-table-column label="籍贯" prop="hometown" />
          <el-table-column label="番号" prop="number" />
        </el-table>
        <el-pagination
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="padding:20px;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisible=false">取 消</el-button>
        <el-button type="primary" @click="handleSelect">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { veteranInfoList } from '@/utils/api.js'
export default {
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      form: {},
      addVisible: false,
      veteranList: [],
      dataListLoading: false,
      current: 1,
      size: 10,
      total: null,
      selectedVeteran: {}
    }
  },
  methods: {
    open() {
      this.addVisible = true
      this.veteranList = []
    },
    async getVeteranList() {
      this.dataListLoading = true
      const res = await veteranInfoList(this.form, this.current, this.size)
      this.dataListLoading = false
      if (res.code === 200) {
        this.veteranList = res.data.records
        this.current = res.data.current
        this.total = res.data.total
      }
    },
    // handleSelectionChange(e) {
    //   this.selectedVeteran = e
    // },
    handleSelect() {
      this.addVisible = false
      console.log(this.selectedVeteran)
      this.$emit('selectSuccess', this.selectedVeteran)
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getVeteranList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getVeteranList()
    }
  }
}
</script>

<style>

</style>
