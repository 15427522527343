<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :title="title"
      width="900px"
      @close="closeHandle"
    >
      <div class="add-edit-box">
        <el-form
          ref="addForm"
          :model="addForm"
          :rules="addFormRules"
          label-width="120px"
        >
          <el-form-item label="资讯标题:" prop="title">
            <el-input v-model="addForm.title" size="small" style="width: 60%" />
          </el-form-item>
          <el-form-item label="相关老兵:" prop="veteranName">
            <el-input
              v-model="addForm.veteranName"
              disabled
              size="small"
              style="width: 46%; margin-right: 10px"
            />
            <el-button
              size="small"
              type="primary"
              @click="openSelectVeteran"
            >选择老兵</el-button>
          </el-form-item>
          <el-form-item label="封面:" prop="picture">
            <el-row>
              <el-col :span="5">
                <el-upload
                  class="avatar-uploader"
                  :action="action"
                  :data="uploadData"
                  :show-file-list="false"
                  :on-success="handlePictureSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="addForm.picture"
                    class="avatar"
                    :src="addForm.picture"
                  >
                  </img>
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </el-col>
              <el-col
                :span="11"
                style="font-size: 12px; color: #999999"
              >图片尺寸为120*76，大小不超过1MB</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="分享海报:" prop="poster">
            <el-row>
              <el-col :span="5">
                <el-upload
                  class="avatar-uploader"
                  :action="action"
                  :data="uploadData"
                  :show-file-list="false"
                  :on-success="handlePosterSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="addForm.poster"
                    :src="addForm.poster"
                    class="avatar"
                  >
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </el-col>
              <el-col
                :span="11"
                style="font-size: 12px; color: #999999"
              >图片尺寸为650*600，大小不超过4MB</el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="资讯内容:" prop="content">
            <div>
              <div>
                <addVeteran
                  color="#1890ff"
                  class="editor-upload-btn"
                  @selectSuccess="editorAddVeteran"
                />
              </div>
              <!-- <tinymce v-model="addForm.content" :value="addForm.content" :addveteran="true" :isupdate="isUpdate" /> -->
              <Html-Editor ref="htmlEditor" :content.sync="addForm.content" />
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="visible = false">取 消</el-button>
          <el-button type="primary" @click="submit('addForm')">发 布</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 选择相关老兵 -->
    <select-veteran ref="selectVeteran" @selectSuccess="selectSuccess" />
  </div>
</template>

<script>
import { addNews, updateNews, getNewsDetail } from '@/utils/api.js'
// import Tinymce from '@/components/Tinymce'
import HtmlEditor from '@/components/HtmlEditor'
import addVeteran from '@/components/AddVeteran'
import SelectVeteran from './SelectVeteran'
import httpRequest from '@/utils/httpRequest.js'
import { host } from '@/config'
export default {
  components: { SelectVeteran, HtmlEditor, addVeteran },
  data() {
    return {
      visible: false,
      title: '新增资讯',
      addForm: {
        title: '',
        picture: '',
        poster: '',
        content: ''
      },
      addFormRules: {
        title: [
          {
            required: true,
            message: '请输入资讯标题',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入资讯内容',
            trigger: 'blur'
          }
        ],
        picture: [
          {
            required: true,
            message: '请上传封面图',
            trigger: 'change'
          }
        ],
        poster: [
          {
            required: true,
            message: '请上传分享海报图',
            trigger: 'change'
          }
        ]
      },
      action: httpRequest.api('/file/upload'),
      uploadData: {
        type: '2',
        imgModel: '6'
      },
      isUpdate: false // 富文本编辑框内容是否需要刷新
    }
  },
  methods: {
    closeHandle() {
      this.addForm = {
        content: ''
      }
      this.$refs.htmlEditor.html = ''
    },
    getEditorContent(content) {
      // this.addForm.content = content
    },
    async open(newsDetail) {
      if (newsDetail) {
        console.log('newsDetail', newsDetail)
        this.title = '编辑资讯'
        this.isUpdate = true
        this.$nextTick(() => {
          this.getDetail(newsDetail.id)
        })
      } else {
        this.title = '新增资讯'
        this.addForm = {}
      }
      this.visible = true
      // 异步函数，在富文本编辑框视图刷新后改变isUpdate值
      Promise.resolve().then(() => {
        this.isUpdate = false
      })
    },
    async getDetail(id) {
      const res = await getNewsDetail(id)
      if (res.success) {
        this.addForm = { ...this.addForm, ...res.data }
        this.$refs.htmlEditor.setHtml(res.data.content)
      }
    },
    // 打开选择相关老兵
    openSelectVeteran() {
      this.$refs.selectVeteran.open()
    },
    editorAddVeteran(arr) {
      const list = []
      arr.forEach((v) => {
        const node = {
          type: 'link',
          url: `/pages/medal/detail?id=${v.id}`,
          children: [{ text: v.name }]
        }
        list.push(node)
      })
      if (list.length) {
        this.$refs.htmlEditor.insertNodes(list)
      }
      // insertNode
      // arr.forEach((v) =>
      //   window.tinymce
      //     .get(this.tinymceId)
      //     .insertContent(
      //       `<a id="myButton" class="name-link" value="${v.id}" href="/pages/medal/detail?id=${v.id}" style="border:none;color:#027AFF;background:#fff;cursor:pointer">${v.name}</a>&nbsp;`
      //     )
      // )
    },
    // 选择相关老兵成功
    selectSuccess(selectedVeteran) {
      this.$set(this.addForm, 'veteranName', selectedVeteran.name)
      this.addForm.veteranId = selectedVeteran.id
    },
    handlePictureSuccess(res) {
      // this.$set(this.addForm, 'picture', res.data)
      if (res.code === 200) {
        const path = `${host}/file/browse/${res.data}`
        this.$set(this.addForm, 'picture', path)
      } else {
        this.$message.error(res.message)
      }
    },
    handlePosterSuccess(res) {
      if (res.code === 200) {
        const path = `${host}/file/browse/${res.data}`
        this.$set(this.addForm, 'poster', path)
      } else {
        this.$message.error(res.message)
      }
      // this.$set(this.addForm, 'poster', res.data)
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!')
      }
      return isLt2M
    },
    async submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.title === '新增资讯') {
            const res = await addNews(this.addForm)
            this.visible = false
            if (res.code === 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.message)
            }
          } else {
            const res = await updateNews(this.addForm)
            this.visible = false
            if (res.code === 200) {
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
            } else {
              this.$message.error(res.message)
            }
          }
          this.$emit('addEditSuccess')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add-edit-box {
  padding: 0 8%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}
</style>
